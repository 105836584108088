/*=========================================================================================
  File Name: moduleDoctorRequestTypeMutations.js
  Description: DoctorRequestType Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.DoctorRequestTypes.unshift(item)
  },
  SET_DoctorRequestType(state, DoctorRequestTypes) {
    state.DoctorRequestTypes = DoctorRequestTypes
  },
  UPDATE_DoctorRequestType(state, DoctorRequestType) {
    const DoctorRequestTypeIndex = state.DoctorRequestTypes.findIndex((p) => p.ID == DoctorRequestType.ID)
    Object.assign(state.DoctorRequestTypes[DoctorRequestTypeIndex], DoctorRequestType)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.DoctorRequestTypes.findIndex((p) => p.ID == itemId)
    state.DoctorRequestTypes.splice(ItemIndex, 1)
},
}
