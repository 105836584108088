/*=========================================================================================
  File Name: moduleDoctorRequestTypeActions.js
  Description: DoctorRequestType Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  AddDoctorRequestType({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/DoctorRequestType/AddDoctorRequestType", item)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetAllDoctorRequestTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("api/DoctorRequestType/GetAllDoctorRequestTypes")
        .then((response) => {
          commit('SET_DoctorRequestType', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  UpdateDoctorRequestType({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/DoctorRequestType/UpdateDoctorRequestType", item)
        .then((response) => {
          commit('UPDATE_DoctorRequestType', item)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetDoctorRequestType({ commit }, itemid) {
    return new Promise((resolve, reject) => {
      axios.post("api/DoctorRequestType/GetDoctorRequestType", itemid)
        .then((response) => {
          commit('UPDATE_DoctorRequestType', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  DeleteDoctorRequestType({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/DoctorRequestType/DeleteDoctorRequestType?ID="+item.ID)
        .then((response) => {
          commit('REMOVE_ITEM', item.ID)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
